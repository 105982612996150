<template>
    <slot :error-message="field.errorMessage.value" />
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useField } from 'vee-validate'

defineOptions({
    inheritAttrs: false,
})

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
})

const { locale } = useI18n()
const field = useField(props.name, undefined, {
    keepValueOnUnmount: true,
})

watch(field.value, () => field.meta.dirty && field.validate(), { deep: true })

watch(locale, () => {
    const { validated, valid } = field.meta

    !valid && validated && field.validate()
})
</script>
