<template>
    <div class="flex text-lg font-bold">
        <span class="text-lg text-blue-600">{{ NAME_PREFIX }}</span>
        <span class="text-lg">{{ NAME }}</span>
    </div>
</template>

<script setup lang="ts">
const NAME_PREFIX = 'e'
const NAME = 'Firma'
</script>
