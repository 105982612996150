<template>
    <div
        class="relative flex h-[350px] w-full flex-col items-center rounded-2xl md:h-[350px] lg:h-[500px]"
    >
        <BaseFeatureCardImage
            v-for="(image, i) in images"
            :key="i"
            :class="[
                'absolute h-full w-full opacity-0 transition-opacity duration-500',
                i === currentIndex && 'opacity-100',
            ]"
            :src="image"
        />

        <div
            v-if="images.length > 1"
            class="absolute -bottom-2 flex w-full items-center justify-center gap-4"
        >
            <span
                v-for="(_, i) in images.length"
                :key="i"
                :class="[
                    'h-2 w-2 cursor-pointer rounded-[50%] shadow-lg',
                    i === currentIndex ? 'bg-blue-500' : 'bg-indigo-200',
                ]"
                @click="onPaginationClick(i)"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { BaseFeatureCardImage } from '#components'

const props = defineProps({
    images: {
        type: Array as () => string[],
        required: true,
    },
    interval: {
        type: Number,
        default: 3500,
    },
})

let changeImageInterval: NodeJS.Timeout | null = null

const currentIndex = ref(0)

onMounted(() => {
    changeImageInterval = setInterval(showImage, props.interval)
})

onUnmounted(() => {
    if (changeImageInterval) clearInterval(changeImageInterval)
})

function showImage(index = currentIndex.value + 1) {
    currentIndex.value = index % props.images.length
}

function onPaginationClick(index: number) {
    currentIndex.value = index

    if (changeImageInterval) {
        clearInterval(changeImageInterval)

        changeImageInterval = setInterval(showImage, props.interval)
    }
}
</script>
