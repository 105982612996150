<template>
    <header class="sticky top-0 z-10 h-16 bg-white p-6">
        <nav class="relative z-10 flex h-full items-center">
            <!-- eFirma logo -->
            <NuxtLink
                :to="
                    localePath({
                        path: localePath('/'),
                        hash: '',
                        replace: true,
                    })
                "
                aria-label="Home"
                class="mr-auto inline-block"
            >
                <AppName />
            </NuxtLink>

            <!-- Links -->
            <div
                class="mr-2 hidden items-center gap-x-0 md:flex lg:mr-20 lg:gap-x-10"
            >
                <a
                    v-for="link in links"
                    :key="link.to"
                    :href="link.to"
                    class="inline-block rounded-lg px-4 py-2 text-sm font-medium text-slate-600 hover:bg-slate-100 hover:text-slate-900"
                >
                    {{ link.text }}
                </a>
            </div>

            <!-- Login -->
            <BaseButton
                external
                color="blue"
                :to="signinUrl"
                class="mr-4 hidden md:flex"
            >
                {{ t('signin') }}
            </BaseButton>

            <!-- Lang switcher -->
            <LangSwitcher class="hidden md:block" />

            <!-- Hamburger -->
            <button
                class="flex h-10 w-10 items-center justify-center rounded-lg bg-blue-100 text-blue-600 md:hidden"
                aria-label="Toggle menu"
                @click="onHamburgerMenuToggle"
            >
                <svg
                    width="20"
                    viewBox="0 0 100 70"
                    class="fill-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="100" height="10"></rect>
                    <rect y="30" width="100" height="10"></rect>
                    <rect y="60" width="100" height="10"></rect>
                </svg>
            </button>
        </nav>

        <!-- Mobile Menu -->
        <div
            class="fixed left-0 top-0 z-50 flex h-full w-full -translate-y-full flex-col bg-gray-100 px-6 transition-transform md:hidden"
            :class="{ 'translate-y-0': isHamburgerMenuOpen }"
        >
            <!-- Top -->
            <div class="flex h-16 w-full items-center justify-between">
                <AppName />

                <!-- Hamburger -->
                <button
                    class="flex h-10 w-10 items-center justify-center rounded-lg bg-blue-100 text-blue-600"
                    @click="onHamburgerMenuToggle"
                >
                    <!-- Close icon -->
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="blue"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            x1="3"
                            y1="3"
                            x2="21"
                            y2="21"
                            stroke="blue"
                            stroke-width="2"
                        />
                        <line
                            x1="21"
                            y1="3"
                            x2="3"
                            y2="21"
                            stroke="blue"
                            stroke-width="2"
                        />
                    </svg>
                </button>
            </div>

            <!-- Middle -->
            <ul class="mt-48 flex w-full flex-1 flex-col items-end gap-8">
                <li
                    v-for="link in links"
                    :key="link.to"
                    class="text-2xl font-light text-blue-600 hover:text-blue-700"
                >
                    <a
                        :href="link.to"
                        class="hover:text-slate-900"
                        @click="onHamburgerMenuToggle"
                    >
                        {{ link.text }}
                    </a>
                </li>
            </ul>

            <!-- Bottom -->
            <div class="flex flex-col items-end justify-end pb-6">
                <BaseButton external color="blue" :to="signinUrl">
                    {{ t('signin') }}
                </BaseButton>

                <LangSwitcher class="mt-4" />
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useAppUrls } from '~/composables/useAppUrls'
import { useI18n } from 'vue-i18n'

const { signinUrl } = useAppUrls()
const localePath = useLocalePath()
const { t } = useI18n()

const links = [
    { to: '#features', text: t('features') },
    // { to: '#plans', text: t('plans') },
    { to: '#about-us', text: t('about_us') },
    { to: '#contact-us', text: t('contact') },
]

const isHamburgerMenuOpen = ref(false)

function onHamburgerMenuToggle() {
    document.body.style.overflow = isHamburgerMenuOpen.value ? 'auto' : 'hidden'
    isHamburgerMenuOpen.value = !isHamburgerMenuOpen.value
}
</script>

<i18n lang="yaml">
en:
    features: 'Features'
    plans: 'Plans'
    about_us: 'About us'
    contact: 'Contact'
    signin: 'Sign in'
bg:
    features: 'Основни функционалности'
    plans: 'Планове'
    about_us: 'За нас'
    contact: 'Контакт'
    signin: 'Вход'
</i18n>
