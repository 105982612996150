export function useAppUrls() {
    const runtimeConfig = useRuntimeConfig()
    const { locale } = useI18n()

    const signinUrl = computed(
        () =>
            runtimeConfig.public.EFIRMA_APP_URL +
            '/signin' +
            `?locale=${locale.value}`,
    )
    const registerUrl = computed(
        () =>
            runtimeConfig.public.EFIRMA_APP_URL +
            '/register' +
            `?locale=${locale.value}`,
    )

    return {
        signinUrl,
        registerUrl,
    }
}
