<template>
    <section
        id="contact-us"
        class="relative"
        :aria-label="`${t('title.contact')}${t('title.us')}`"
    >
        <!-- Gradient -->
        <div
            class="pointer-events-none absolute inset-0 bg-gradient-to-tl from-blue-600/20 from-0% to-white to-60%"
        />

        <BaseContainer
            class="relative flex max-w-[800px] flex-col items-center justify-center gap-6 md:gap-20"
        >
            <h2 class="text-center text-3xl font-bold md:text-5xl">
                <span class="text-blue-600">{{ t('title.contact') }}</span>
                <span>{{ t('title.us') }}</span>
            </h2>

            <BaseForm
                v-slot="{ submit }"
                :form="form"
                :schema="schema"
                class="grid w-full grid-cols-2 gap-4"
                @submit="onSubmit"
            >
                <BaseValidation v-slot="{ errorMessage }" name="name">
                    <BaseInput
                        id="name"
                        v-model="form.name"
                        :disabled="isMessageSent"
                        :label="t('labels.name')"
                        :error-message="errorMessage"
                        clearable
                        required
                        autocomplete="first-name"
                        class="col-span-2 md:col-span-1"
                        @clear="form.name = ''"
                    />
                </BaseValidation>

                <BaseInput
                    id="surname"
                    v-model="form.surname"
                    :disabled="isMessageSent"
                    :label="t('labels.surname')"
                    clearable
                    autocomplete="family-name"
                    class="col-span-2 md:col-span-1"
                    @clear="form.surname = ''"
                />

                <BaseValidation v-slot="{ errorMessage }" name="email">
                    <BaseInput
                        id="email"
                        v-model="form.email"
                        :disabled="isMessageSent"
                        :label="t('labels.email')"
                        :error-message="errorMessage"
                        type="email"
                        required
                        clearable
                        autocomplete="email"
                        class="col-span-2"
                        @clear="form.email = ''"
                    />
                </BaseValidation>

                <BaseValidation v-slot="{ errorMessage }" name="message">
                    <BaseTextArea
                        id="message"
                        v-model="form.message"
                        :disabled="isMessageSent"
                        :error-message="errorMessage"
                        required
                        :label="t('labels.message')"
                        class="col-span-2"
                    />
                </BaseValidation>

                <BaseButton
                    :color="isMessageSent ? 'green' : 'blue'"
                    type="submit"
                    :loading="isLoading"
                    :disabled="isMessageSent"
                    class="col-span-2 md:col-span-1 md:!mt-6"
                    @click.prevent="submit"
                >
                    <!-- Check mark svg -->
                    <svg
                        v-if="isMessageSent"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-3 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                        />
                    </svg>

                    {{
                        isMessageSent
                            ? t('labels.submit-successful')
                            : t('labels.submit')
                    }}
                </BaseButton>

                <p
                    v-if="isMessageError"
                    class="col-span-2 text-sm text-red-500"
                >
                    {{ t('error-message') }}
                </p>
            </BaseForm>
        </BaseContainer>
    </section>
</template>

<script setup lang="ts">
import { z } from 'zod'

const { t } = useI18n()

const form = ref({
    name: '',
    surname: '',
    email: '',
    message: '',
})

// zod schema
const schema = z.object({
    name: z.string().min(1, t('validation.name')),
    email: z.string().email(t('validation.email')),
    message: z.string().min(1, t('validation.message')),
})

const isLoading = ref(false)
const isMessageSent = ref(false)
const isMessageError = ref(false)

async function onSubmit() {
    isLoading.value = true

    const { EFIRMA_APP_URL } = useRuntimeConfig().public

    try {
        await $fetch(`${EFIRMA_APP_URL}/api/v1/support-email`, {
            method: 'POST',
            body: JSON.stringify(form.value),
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'json',
        })

        isMessageError.value = false
        isMessageSent.value = true
    } catch (error) {
        $sentry.captureException(error)

        isMessageError.value = true
    } finally {
        isLoading.value = false
    }
}
</script>

<i18n lang="yaml">
en:
    title:
        contact: 'Contact '
        us: 'us:'
    labels:
        name: 'Name'
        surname: 'Surname'
        email: 'E-mail'
        message: 'Message'
        submit: 'Submit'
        submit-successful: 'Message sent successfully'
        how-can-we-help: 'How can we help you?'
    validation:
        name: 'Name is required'
        email: 'Invalid email'
        message: 'Message is required'
    error-message: 'An error occurred while sending the message. Please try again later.'

bg:
    title:
        contact: 'Свържете се '
        us: 'с нас:'
    labels:
        name: 'Име'
        surname: 'Фамилия'
        email: 'Имейл'
        message: 'Съобщение'
        submit: 'Изпрати запитване'
        submit-successful: 'Запитването е изпратено успешно'
        how-can-we-help: 'Как можем да Ви помогнем?'
    validation:
        name: 'Името е задължително'
        email: 'Невалиден имейл'
        message: 'Съобщението е задължително'
    error-message: 'Възникна грешка при изпращането на съобщението. Моля, опитайте отново по-късно.'
</i18n>
