<template>
    <footer class="bg-slate-50">
        <BaseContainer class="flex">
            <div class="flex flex-1 flex-col">
                <AppName />

                <p class="text-gray-700">{{ t('description') }}</p>

                <BaseButton :to="registerUrl" class="my-6 self-start">
                    {{ t('register') }}
                </BaseButton>

                <p class="text-gray-500">{{ t('copyright', { year }) }}</p>
            </div>

            <div class="hidden flex-col justify-end gap-5 text-right lg:flex">
                <a
                    v-for="link in links"
                    :key="link.label"
                    :href="link.to"
                    class="font-medium text-gray-700 hover:text-blue-600"
                >
                    {{ link.label }}
                </a>
            </div>
        </BaseContainer>
    </footer>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { registerUrl } = useAppUrls()

const year = new Date().getFullYear()

const links = computed(() => ({
    features: {
        label: t('links.features'),
        to: '/#features',
    },
    // plans: { label: t('links.plans'), to: '/#plans' },
    aboutUs: { label: t('links.about-us'), to: '/#about-us' },
    contacts: { label: t('links.contacts'), to: '/#contact-us' },
}))
</script>

<i18n lang="yaml">
en:
    description: 'Simplified accounting platform, assisting small and large businesses.'
    register: 'Free registration'
    # copyright: 'Terms of Service • © {year}. eFirma. All rights reserved'
    copyright: '© {year}. eFirma. All rights reserved'
    links:
        features: 'Key Features'
        plans: 'Plans'
        about-us: 'About Us'
        contacts: 'Contacts'
bg:
    description: 'Опростена платформа за счетоводство, подпомагаща малки и големи фирми.'
    register: 'Безплатна регистрация'
    # copyright: 'Общи условия • © {year}. eFirma. Всички права запазени'
    copyright: '© {year}. eFirma. Всички права запазени'
    links:
        features: 'Основни функционалности'
        plans: 'Планове'
        about-us: 'За нас'
        contacts: 'Контакти'
</i18n>
