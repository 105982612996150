<template>
    <div
        :class="[
            'relative flex flex-col gap-20 p-4 md:flex-row md:p-8',
            reversed && 'md:flex-row-reverse',
        ]"
    >
        <div
            class="flex w-full flex-col justify-center gap-8 md:w-2/5 md:min-w-[360px]"
        >
            <!-- Title -->
            <div class="flex items-center gap-3">
                <img
                    :src="iconSrc"
                    :alt="`${title} title icon`"
                    height="40"
                    width="40"
                />

                <h3 class="text-2xl font-semibold capitalize">{{ title }}</h3>
            </div>

            <!-- List of items -->
            <ul class="flex flex-col gap-2">
                <li
                    v-for="(item, i) in items"
                    :key="i"
                    class="flex gap-2 text-gray-600"
                >
                    <!-- Tick icon -->
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="inline-block shrink-0"
                        fill="currentColor"
                    >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                        />
                    </svg>

                    <!-- Title -->
                    <p class="ml-2 text-lg leading-6">
                        {{ item }}
                    </p>
                </li>
            </ul>
        </div>

        <!-- Images -->
        <div class="relative md:w-3/5">
            <!-- SVG -->
            <img
                v-for="(svg, i) in svgs"
                :key="i"
                :alt="`${title} illustration ${i}`"
                :src="svg.src"
                :style="getSVGStyle(svg)"
                class="absolute hidden lg:block"
            />

            <FeatureCardImageCarousel :images="images" />
        </div>
    </div>
</template>

<script setup lang="ts">
import FeatureCardImageCarousel from './FeatureCardImageCarousel.vue'

export interface FeatureCardSVG {
    src: string
    left?: number
    right?: number
    top?: number
    bottom?: number
}

defineProps({
    title: {
        type: String,
        required: true,
    },
    items: {
        type: Array as PropType<string[]>,
        required: true,
    },
    images: {
        type: Array as PropType<string[]>,
        required: true,
    },
    iconSrc: {
        type: String,
        required: true,
    },
    reversed: {
        type: Boolean,
        default: false,
    },
    svgs: {
        type: Array as PropType<FeatureCardSVG[]>,
        required: true,
    },
})

function getSVGStyle(svg: FeatureCardSVG) {
    return {
        left: svg.left ? `${svg.left}px` : undefined,
        right: svg.right ? `${svg.right}px` : undefined,
        top: svg.top ? `${svg.top}px` : undefined,
        bottom: svg.bottom ? `${svg.bottom}px` : undefined,
    }
}
</script>
