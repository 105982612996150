<template>
    <section class="relative" :aria-label="t('title')">
        <!-- Gradient background -->
        <div
            class="from-10 pointer-events-none absolute inset-0 bg-gradient-to-b from-white from-10% to-blue-600/10 to-100%"
        />

        <BaseContainer class="relative flex flex-col gap-20">
            <!-- Header -->
            <div class="gap-4 text-center">
                <h2 class="mb-4 text-4xl font-bold leading-10">
                    {{ t('title') }}
                </h2>

                <p class="text-xl leading-7">{{ t('subheader') }}</p>
            </div>

            <!-- Cards -->
            <div
                class="grid grid-cols-1 flex-col justify-center gap-6 text-left md:flex-row lg:grid-cols-3"
            >
                <div
                    v-for="card in cards"
                    :key="card.title"
                    class="flex w-full flex-col gap-4 rounded-2xl border-2 border-white bg-white/30 p-6"
                >
                    <!-- Icon -->
                    <img
                        :src="card.icon"
                        :alt="`${card.title} icon`"
                        class="h-12 w-12"
                    />

                    <p class="font-xl font-semibold leading-7">
                        {{ card.title }}
                    </p>

                    <p class="leading-6 text-gray-600">
                        {{ card.description }}
                    </p>
                </div>
            </div>
        </BaseContainer>
    </section>
</template>

<script setup lang="ts">
const { t } = useI18n()

const cards = computed(() => [
    {
        title: t('cards.security.title'),
        description: t('cards.security.description'),
        icon: '/icons/security-lock.svg',
    },
    {
        title: t('cards.communication.title'),
        description: t('cards.communication.description'),
        icon: '/icons/communication.svg',
    },
    {
        title: t('cards.analysis.title'),
        description: t('cards.analysis.description'),
        icon: '/icons/analysis.svg',
    },
])
</script>

<i18n lang="yaml">
en:
    title: 'Shared workspace with your accountant'
    subheader: 'You have the opportunity to take advantage of the benefits of a shared workspace with your accountant and/or colleague. Easier and faster communication, guaranteed data storage and timely review of the current financial status of your business.'
    cards:
        security:
            title: 'Data security'
            description: 'Full protection for your data! All issued, entered and uploaded documents are secured with the latest encryption and security technologies. We guarantee 100% confidentiality.'
        communication:
            title: 'Streamlined communication'
            description: 'Optimize your teamwork! By providing fast and easy information exchange between you and your accountant, we save you time and potential errors. There is a choice between different roles and rights.'
        analysis:
            title: 'Timely analysis'
            description: 'Make informed decisions with ease! Every change in revenues and expenses is reflected immediately, providing a real-time picture of the financial status of your business.'
bg:
    title: 'Споделено пространство за работа с Вашия счетоводител'
    subheader: 'Имате възможността да се възползвате от предимствата на обща работна платформа с Вашия счетоводител и/или колега. По-лесна и бърза комуникация, гарантирано съхранение на данните и навременен преглед на актуалното финансово състояние на Вашия бизнес.'
    cards:
        security:
            title: 'Сигурност на данните'
            description: 'Пълна защита за Вашите данни! Всички издадени, въведени и качени документи са обезпечени с най-новите технологии за криптиране и сигурност. Гарантираме 100% конфиденциалност.'
        communication:
            title: 'Улеснена комуникация'
            description: 'Оптимизирайте съвместната работа! Осигурявайки бърз и лесен обмен на информация между Вас и Вашия счетоводител, ние Ви спестяваме време и потенциални грешки. Има избор между различни роли и права.'
        analysis:
            title: 'Навременен анализ'
            description: 'Вземайте информирани решения с лекота! Всяка промяна в приходите и разходите се отразява незабавно, предоставяйки актуална картина на финансовото състояние на Вашия бизнес в реално време.'
</i18n>
