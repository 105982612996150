<template>
    <component
        :is="props.to ? NuxtLink : 'button'"
        :to="to"
        :type="type"
        :disabled="disabled || loading"
        :class="[
            'relative h-10',
            disabled && 'cursor-not-allowed',
            baseStyles[variant],
            variantStyles[variant][color],
        ]"
        v-bind="$attrs"
    >
        <span
            v-if="loading"
            class="absolute inset-0 flex h-full w-full items-center justify-center"
        >
            <BaseProgressCircular size="70%" class="loader" />
        </span>

        <slot v-else />
    </component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

export interface Props {
    variant?: 'solid' | 'outline'
    color?: 'slate' | 'blue' | 'white' | 'green'
    to?: string
    type?: HTMLButtonElement['type']
    loading?: boolean
    disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    variant: 'solid',
    color: 'slate',
    to: undefined,
    type: 'button',
})

const baseStyles: Record<NonNullable<Props['variant']>, string> = {
    solid: 'group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
    outline:
        'group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none',
}

const variantStyles: Record<
    NonNullable<Props['variant']>,
    Record<NonNullable<Props['color']>, string>
> = {
    solid: {
        slate: 'bg-slate-900 text-white ui-not-disabled:hover:bg-slate-700 ui-not-disabled:hover:text-slate-100 ui-not-disabled:active:bg-slate-800 ui-not-disabled:active:text-slate-300 focus-visible:outline-slate-900',
        blue: 'bg-blue-600 text-white ui-not-disabled:hover:text-slate-100 ui-not-disabled:hover:bg-blue-500 ui-not-disabled:active:bg-blue-800 ui-not-disabled:active:text-blue-100 focus-visible:outline-blue-600',
        white: 'bg-white text-slate-900 ui-not-disabled:hover:bg-blue-50 ui-not-disabled:active:bg-blue-200 ui-not-disabled:active:text-slate-600 focus-visible:outline-white',
        green: 'bg-green-600 text-white ui-not-disabled:hover:bg-green-500 ui-not-disabled:active:bg-green-800 ui-not-disabled:active:text-green-100 focus-visible:outline-green-600',
    },
    outline: {
        slate: 'ring-slate-200 text-slate-700 ui-not-disabled:hover:text-slate-900 ui-not-disabled:hover:ring-slate-300 ui-not-disabled:active:bg-slate-100 ui-not-disabled:active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300',
        blue: '',
        white: 'ring-slate-700 text-white ui-not-disabled:hover:ring-slate-500 ui-not-disabled:active:ring-slate-700 ui-not-disabled:active:text-slate-400 focus-visible:outline-white',
        green: 'ring-green-200 text-green-600 ui-not-disabled:hover:text-green-800 ui-not-disabled:hover:ring-green-300 ui-not-disabled:active:bg-green-100 ui-not-disabled:active:text-green-700 focus-visible:outline-green-600 focus-visible:ring-green-300',
    },
}
</script>
