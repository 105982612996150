<template>
    <form @submit.prevent>
        <slot :submit="onSubmit" />

        <!-- to be able to submit with Enter, when there's no type="submit" button/input within the form -->
        <input type="submit" class="hidden" />
    </form>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import type { ZodRawShape, ZodObject, ZodEffects } from 'zod'

type ZodSchema = ZodObject<ZodRawShape>
type Schema = ZodSchema | ZodEffects<ZodSchema>

const props = defineProps({
    form: {
        type: Object as PropType<Record<string, any>>,
        required: true,
    },
    schema: {
        type: Object as PropType<Schema>,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits<{
    (event: 'submit'): void
    (event: 'error', errors: Record<string, any>): void
}>()

const formInstance = useForm({
    initialValues: props.form,
    validationSchema: toTypedSchema(props.schema),
})

async function onSubmit() {
    if (props.disabled) return

    const validation = await formInstance.validate()

    if (validation.valid) emit('submit')
    else {
        emit('error', validation.errors)
    }
}

watch(
    () => props.form,
    () => formInstance.setValues(props.form, false),
    { deep: true },
)

defineExpose({
    onSubmit,
    setErrors: formInstance.setErrors,
    resetForm: formInstance.resetForm,
})
</script>
