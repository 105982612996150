<template>
    <picture class="flex items-center justify-center">
        <source
            :media="`(max-width: ${screens.md})`"
            :type="`image/${extension}`"
            :srcset="src"
        />

        <img
            :src="fullResolutionSrc"
            alt="Responsive image"
            class="h-full object-contain"
        />
    </picture>
</template>

<script setup lang="ts">
const screens = {
    md: '768px',
}

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
})

const extension = computed(() => {
    const extension = props.src.split('.').pop()

    if (!extension) {
        throw new Error(`Could not parse image source: ${props.src}`)
    }

    return extension
})

const fullResolutionSrc = computed(() => {
    return props.src.replace(`.${extension.value}`, `@2x.${extension.value}`)
})
</script>
