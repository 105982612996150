<template>
    <label
        :for="$props.for"
        :class="[
            'block self-start text-left text-sm font-medium text-gray-700',
            spacingMargin,
        ]"
    >
        <slot />

        <span v-if="required" class="ml-1 font-extrabold text-red-600">
            {{ requiredSymbol }}
        </span>
    </label>
</template>

<script setup lang="ts">
const props = defineProps({
    for: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
    requiredSymbol: {
        type: String,
        default: '*',
    },
    spacing: {
        type: [String, Boolean] as PropType<'bottom' | 'left' | boolean>,
        default: 'bottom',
    },
})

const spacingMargin =
    props.spacing && (props.spacing === 'bottom' ? 'mb-1' : 'ml-2')
</script>
