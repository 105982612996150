<template>
    <div>
        <!-- Label  -->
        <BaseLabel v-if="label" :for="id" :required="required">
            {{ label }}
        </BaseLabel>

        <div class="relative">
            <!-- Input -->
            <textarea
                :id="id"
                ref="textarea"
                :value="modelValue"
                :rows="minRows"
                :maxlength="maxlength"
                :class="[
                    'mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm',
                    errorMessage &&
                        '!border-red-300 pr-8 text-red-900 placeholder-red-300 focus:!border-red-500 focus:!ring-red-500',
                    disabled && 'cursor-not-allowed opacity-50',
                    autoresize && 'overflow-hidden',
                ]"
                :disabled="disabled"
                @input="onInput"
                @change="onChange"
            />

            <!-- Error state -->
            <Transition name="fade">
                <div
                    v-if="errorMessage"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                    <ExclamationCircleIcon
                        class="h-5 w-5 text-red-500"
                        aria-hidden="true"
                    />
                </div>
            </Transition>
        </div>

        <p v-if="errorMessage" class="mt-1 text-xs text-red-500">
            {{ errorMessage }}
        </p>

        <p v-if="hint" class="mt-2 text-sm leading-5 text-gray-500">
            {{ hint }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    id: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    hint: {
        type: String,
        default: '',
    },
    minRows: {
        type: Number,
        default: 4,
    },
    errorMessage: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    autoresize: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    maxlength: {
        type: Number,
        default: 6000,
    },
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: string): string
}>()

const textarea = ref<HTMLTextAreaElement | null>(null)

onMounted(() => {
    if (props.autoresize) {
        resize()
    }
})

function onInput() {
    if (props.autoresize) {
        resize()
    }
}

function onChange(event: Event) {
    const val = (event.target as HTMLTextAreaElement).value

    emit('update:modelValue', val)
}

function resize() {
    const el = textarea.value

    if (!el) return

    nextTick(() => {
        el.style.height = 'auto'
        if (el.scrollHeight > el.clientHeight) {
            el.style.height = `${el.scrollHeight}px`
        }
    })
}
</script>
