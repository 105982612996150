<template>
    <div
        class="flex flex-col items-start rounded-2xl border border-gray-100 bg-white p-6 shadow-xl"
    >
        <!-- Text -->
        <div class="flex w-full items-center justify-between gap-3">
            <p class="text-xl font-semibold leading-7">{{ title }}</p>

            <!-- Button -->
            <button
                :id="`${uuid}-button`"
                :aria-expanded="isOpen"
                :aria-controls="`${uuid}-description`"
                aria-label="Expand button"
                :class="[
                    'flex h-11 w-11 shrink-0 items-center justify-center rounded-lg ring-2 ring-transparent ring-offset-2 focus:outline-none focus:ring-blue-600',
                    isOpen ? 'bg-blue-600' : 'bg-blue-100',
                ]"
                @click="isOpen = !isOpen"
            >
                <!-- Close icon -->
                <svg
                    v-if="isOpen"
                    width="20"
                    height="3"
                    viewBox="0 0 20 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 0.150903C1.36487 0.150903 0.85 0.665776 0.85 1.3009C0.85 1.93603 1.36487 2.4509 2 2.4509H18C18.6351 2.4509 19.15 1.93603 19.15 1.3009C19.15 0.665776 18.6351 0.150903 18 0.150903H2Z"
                        fill="white"
                        stroke="white"
                        stroke-width="0.3"
                        stroke-linecap="round"
                    />
                </svg>

                <!-- Open icon -->
                <svg
                    v-else
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.95629 1.00637C9.28861 1.00637 8.74734 1.54764 8.74734 2.21532V19.0355C8.74734 19.7031 9.2886 20.2444 9.95629 20.2444C10.624 20.2444 11.1652 19.7031 11.1652 19.0355V2.21532C11.1652 1.54764 10.624 1.00637 9.95629 1.00637Z"
                        fill="#2563EB"
                        stroke="#2563EB"
                        stroke-width="0.315378"
                        stroke-linecap="round"
                    />
                    <path
                        d="M1.54625 9.41653C0.87857 9.41653 0.337306 9.95779 0.337306 10.6255C0.337306 11.2932 0.878569 11.8344 1.54625 11.8344H18.3664C19.0341 11.8344 19.5753 11.2932 19.5753 10.6255C19.5753 9.95779 19.0341 9.41653 18.3664 9.41653H1.54625Z"
                        fill="#2563EB"
                        stroke="#2563EB"
                        stroke-width="0.315378"
                        stroke-linecap="round"
                    />
                </svg>
            </button>
        </div>

        <p
            v-if="isOpen"
            :id="`${uuid}-description`"
            :hidden="!isOpen"
            class="mt-3 pr-14 text-lg leading-7 text-gray-600"
        >
            {{ description }}
        </p>
    </div>
</template>

<script setup lang="ts">
defineProps({
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
})

const isOpen = defineModel({
    type: Boolean,
    required: true,
})

const uuid = useId()
</script>
